.container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.8rem;
  border-radius: 4px;
  border: 1px dashed #4431b8;
  background: #fff;
  box-shadow: 0px 0px 0px 0px rgba(176, 176, 176, 0.1),
    0px 3px 7px 0px rgba(176, 176, 176, 0.1),
    0px 29px 17px 0px rgba(176, 176, 176, 0.05),
    0px 52px 21px 0px rgba(176, 176, 176, 0.01),
    0px 81px 23px 0px rgba(176, 176, 176, 0);
}

.title {
  color: #252525;
  font-size: 18px;
  font-weight: 500;
}

.main {
  display: grid;
  grid-template-columns: 9fr 3fr;
  gap: 1.5rem;
}

@media screen and (max-width: 1000px) {
  .main {
    grid-template-columns: 1fr;
  }
}

.input {
  flex: 1 !important;
  border-radius: 2px !important;
  border: none !important;
  background: #f2f2f2 !important;
  padding: 7px 10px !important;
  min-width: 100px !important;
}

.title_input {
  flex: 1 !important;
  border-radius: 2px !important;
  border: none !important;
  padding: 7px 0px 7px 10px !important;
  min-width: 100px !important;
}

.title_input:hover {
  box-shadow: none Im !important;
  outline: #4431b8 !important;
}

.btns {
  background: #4431b8 !important;
  text-transform: none !important;
  color: white !important;
}

.btns:disabled {
  color: white !important;
  background-color: rgba(67, 49, 184,0.5) !important;
  opacity: 0.8 !important;
}

.reset_btn {
  color: white !important;
  background-color: red !important;
  text-transform: none !important;
}
