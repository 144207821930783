.container {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    /* gap: 1.5rem; */
    gap: 1.3rem;
    border-radius: 16px;
    background: rgba(252, 252, 255, 1);
    box-shadow: 0px 4px 32px 0px rgba(64, 64, 64, 0.06);
    /* box-shadow: 0px 4px 80px 0px rgba(64, 64, 64, 0.22); */
    /* margin: 2rem 2rem 0 0; */
    /* height: max-content; */
    /* flex-basis: 40%; */
    /* grid-row: 1/3; */
    /* grid-column: 2; */
    max-height: 482px;
    overflow-y: scroll;
}

@media screen and (max-width: 1000px) {
    .container{
        max-height: inherit;
    }
}



.flex_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.flex_col_gap {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.label {
    color: var(--primary-text);
    /* font-family: Poppins; */
    font-size: 15px;
}

.value {
    color: var(--secondary-text);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
}

.caption {
    color: #3C3C3C;
    font-size: 11px;
    font-weight: 400;
}

.status {
    text-align: center;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    width: 80px;
    border-radius: 4px;
    padding: 3px 10px;
    cursor: pointer;
}

.active_status {
    color: #34A855;
    background-color: #34A8550F;
    border-color: #34A855;
}

.inactive_status {
    color: #FE9800;
    background-color: #FFB64824;
    border-color: #FE9800;
}

.suspended_status {
    color: #FD3246;
    background-color: #FD32461F;
    border-color: #FD3246;
}

.detailContainer {
    display: flex;
    align-items: center;
    gap:"1rem";
    max-width:"300px"
}

.detailContainerHeading {
    margin: 0;
    /* width: 50%; */
    font-size: 0.9rem;
    font-weight: 600;
}

.detailContainerDesc {
    /* font-weight: 600; */
    color: rgba(122, 122, 122, 1);
    font-size: 0.9rem;
    margin: 0;
    /* width: 50%; */
    /* min-width: 150px; */
}