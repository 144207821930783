.container {
  padding: 3rem;
}
.Header {
  justify-content: space-between;
}
.container {
  padding: 1rem 3rem;
  width: 100%;
}

.TwoFieldContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 20px;
  margin: 10px 0;
}

.OneFieldContainer {
  margin: 10px 0;
}

@media screen and (max-width: 500px) {
  .TwoFieldContainer {
    grid-template-columns: 1fr;
  }
}
