.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
}

.blank_img {
    width: clamp(150px, 20vw, 300px);
    aspect-ratio: 1;
}