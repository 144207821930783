.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 6px;
    background: #FFF;
    padding: 1rem;
    padding-bottom: 5rem;
}

.search_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.table_header {
    display: grid;
    grid-template-columns: 0.7fr 2.5fr repeat(4, 1fr);
    gap: 0.5rem;
}

.table_header>div {
    padding: 5px;
}

.label {
    color: var(--primary-text);
    /* font-family: Inter; */
    font-size: 16px;
}

.small_col {
    text-align: center;
}

.table_rows_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.user_column {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.user_column>span {
    color: var(--primary-text);
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
}

.avatar {
    height: 45px;
    width: 45px;
    border-radius: 50%;
}

.popup_class :global .MuiPaper-rounded {
    border-radius: 16px !important;
}

.action_container {
    display: flex;
    gap: 1rem;
}

.status {
    text-align: center;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    width: max-content;
    border-radius: 4px;
    padding: 3px 15px;
    cursor: pointer;
}

.active_status {
    color: #34A855;
    background-color: #34A8550F;
    border-color: #34A855;
}

@media screen and (max-width: 900px) {

    .label,
    .user_column>span {
        font-size: 10px;
    }

    .avatar {
        height: 30px;
        width: 30px;
    }

    .status {
        font-size: 10px;
        padding: 2px 10px;
        border-radius: 3px;
    }

}

@media screen and (max-width: 767px) {

    .label,
    .user_column>span {
        font-size: 7px;
    }

    .status {
        font-size: 7px;
        padding: 1px 5px;
        border-radius: 1px;
    }

    .icon_btn {
        height: 20px !important;
        width: 20px !important;
    }
}