.container {
    position: fixed;
    bottom: 0;
    /* left: 0; */
    right: 0;
    /* width: 100vw; */
    width: 45%;
    height: 100vh;
    overflow: hidden;
    opacity: 1;
    /* transition: opacity 0.5s linear; */
    transition: opacity 0.2s linear, height 0.2s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    /* background-color: rgba(0, 0, 0, 0.2); */
}

@media screen and (max-width:1100px) {
    .container {
        width: 90%;
    }
}

.collapse {
    height: 0 !important;
    opacity: 0 !important;
}

.popup_container {
    position: absolute;
    z-index: 15;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    /* transition: all 0.2s cubic-bezier(.7, .03, .21, 1.35); */

    width: max-content;
    transition: bottom 0.2s ease-out;
}

.close_popup {
    bottom: 0 !important;
}



.main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: max-content;
    border-radius: 22px;
    border: 1px solid #CACACA;
    background: #FFF;
    padding: 1rem 0 1.5rem 0;
    position: relative;
    margin-bottom: 2rem;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #E8E8E8;
    padding: 7px;
    flex: 1;
    margin: 0 1rem;
}

.header>span {
    color: rgba(131, 131, 131, 0.85);
    font-size: 18px;
}

.options_container {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    gap: 2px;
}

.request_btn_container {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    width: 70%;
}