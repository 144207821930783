.container {
    display: grid;
    /* grid-template-columns: 1fr 0.25fr; */
    grid-template-columns: 1fr 0.35fr;
    height: 100%;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem 3rem;
    border-left: 1px solid var(--color-divider);
    border-right: 1px solid var(--color-divider);
}

.right_side_bar {
    padding: 2rem 1.5rem;
}

.form_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.grid_container,
.inner_grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 4rem;
    align-items: end;
}

.flex_col_gap {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.label {
    color: var(--primary-text);
    text-transform: capitalize;
    font-family: Inter, Roboto, sans-serif;
    font-size: 13.418px;
    font-weight: 600;
}

.invoice_detail_container {
    display: flex;
    gap: 0.3rem;
    flex-direction: column;
}

.invoice_field_container {
    display: grid;
    gap: 1rem;
    grid-template-columns: 0.7fr 1.3fr;
}

.field_container {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.declarations_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.declaration_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    padding: 1rem;
    border-radius: 2px;
    border: 0.55px solid #C9C9C9;
}

.declaration_title {
    color: var(--primary-text);
    font-size: 12px;
    font-family: Inter, Roboto, sans-serif;
    font-weight: 600;
}

.declaration_field_label {
    color: var(--primary-text);
    text-transform: capitalize;
    font-family: Inter, Roboto, sans-serif;
    font-size: 12px;
}

@media screen and (max-width:1100px) {
    .main {
        padding: 1.5rem;

    }

    .right_side_bar {
        padding: 1.5rem;
    }

    .grid_container,
    .inner_grid_container {
        gap: 1rem 1.5rem;
    }

    .inner_grid_container {
        grid-template-columns: auto;
    }

    .label {
        font-size: 11px;
    }

    .declaration_field_label {
        font-size: 10px;
    }
}

@media screen and (max-width:700px) {
    .main {
        padding: 1rem;

    }

    .right_side_bar {
        padding: 1rem;
    }

    .grid_container,
    .inner_grid_container {
        gap: 1rem;
    }

    .label {
        font-size: 9px;
    }

    .declaration_field_label {
        font-size: 8px;
    }
}