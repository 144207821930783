.main{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    min-width: 250px;
    border-radius: 6px;
    /* height: 100%; */
    /* margin: 1rem 0; */
    /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; */
}
.main > img{
    width: 70px;
}
.main > p{
    margin: 1rem 0 0 0;
    font-size: 12px;
    max-width: 150px;
    text-align: center;
}