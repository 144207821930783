.mainContainer {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 20px;
  grid-template-areas:
    'UserProfileBox ProfileActionBox'
    'ProfileActivityBox ProfileActionBox';
}

.UserProfileBox {
  grid-area: UserProfileBox;
}

.ProfileActionBox {
  grid-area: ProfileActionBox;
}

.ProfileActivityBox {
  grid-area: ProfileActivityBox;
}

@media screen and (max-width: 1000px) {
  .mainContainer {
    grid-template-columns: auto;
    grid-template-areas:
      'UserProfileBox'
      'ProfileActionBox'
      'ProfileActivityBox';
  }
}
