.main{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}


.card{
    border-radius: 10px;
    /* border:2px solid black; */
    background-color: rgb(55, 55, 133);
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: aliceblue;
    cursor: pointer;
}