.container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: #fff;
}

.top_bar {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
}

.middle_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
}

.select_attachments :global .MuiInputLabel-root,
.select_attachments :global .MuiSelect-icon {
    color: var(--primary) !important;
    font-weight: 600 !important;
}

.select_attachments :global .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--primary) !important;
}

.select_attachments_menu :global .MuiMenuItem-root:hover {
    background-color: #fff !important;
}

.select_attachments_menu {
    background-color: #fff !important;
}

.select_attachments_menu :global .Mui-selected>.attachment_item {
    border-radius: 6px;
    border: 1px solid var(--primary);
    background: rgba(68, 49, 184, 0.12);
}

.attachment_item {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 100%;
    padding: 0.1rem 0.5rem;
    border: 1px solid transparent;
}

.attachment_item:hover {
    border-radius: 6px;
    border: 1px solid var(--primary);
    background: rgba(68, 49, 184, 0.12);
}

.attachment_item img {
    max-width: 15px;
}

.attachment_item span {
    font-size: clamp(11px, 2vw, 14px);
    color: var(--secondary-text);
    overflow: hidden;
    text-overflow: ellipsis;
}