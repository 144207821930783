.container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.imageStyle{
    width: 100px;
    height: 100px;
    background-color: #4431BB !important;
}

.service_icon{
    background-color: #4431BB;
    width: max-content;
    border-radius: 10px;
}