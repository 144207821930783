.container {
    --_gap: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.page_heading {
    display: flex;
    justify-content: end;
    color: var(--secondary-text);
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.grid_container_4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--_gap);
}

.grid_container_2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--_gap);
}

.flex_col {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.flex_gap {
    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
}

.label {
    color: var(--secondary-text);
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
}

.value {
    color: var(--primary-text);
    font-family: Inter;
    font-size: 12px;
}