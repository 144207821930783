.activity_title {
    font-size: 0.7rem !important;
    flex-basis: 70%;
}

.avatar {
    height: 25px !important;
    width: 25px !important;
    border-radius: 50%;
}

.user_name {
    font-size: 0.7rem !important;
}

.date_time {
    font-size: 0.6rem !important;
}

.btn {
    font-size: 9px !important;
    padding: 3px 7px !important;
}