.container {
    display: grid;
    grid-template-columns: 1fr 0.35fr;
    height: 100%;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem 3rem;
    border-left: 1px solid var(--color-divider);
    border-right: 1px solid var(--color-divider);
}

.right_side_bar {
    padding: 2rem 1.5rem;
}

.form_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.grid_container,
.inner_grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    gap: 1rem 4rem;
}

.flex_col_gap_1 {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}


@media screen and (max-width:1100px) {
    .main {
        padding: 1.5rem;

    }

    .right_side_bar {
        padding: 1.5rem;
    }

    .grid_container,
    .inner_grid_container {
        gap: 1rem 1.5rem;
    }

    .inner_grid_container {
        grid-template-columns: auto;
    }
}

@media screen and (max-width:700px) {
    .main {
        padding: 1rem;

    }

    .right_side_bar {
        padding: 1rem;
    }

    .grid_container,
    .inner_grid_container {
        gap: 1rem;
    }
}