.table_row {
    /* padding: 1em 0.5em;
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    background-color: white;
    overflow: hidden; */
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    gap: 0.5rem;
}

.table_row:hover {
    /* background-color: aliceblue; */
}

.table_row>div {
    /* display: flex;
    gap: 0.5em;
    align-items: center;
    padding: 0 1em;
    flex-basis: 100%; */
    color: #4E4E4E;
    font-family: Inter;
    /* font-size: 12px; */
    font-size: clamp(8px, 1.5vw, 12px);
    text-align: center;
    padding: 5px;
}

.extend {
    background-color: white;
    overflow: hidden;
    max-height: 500px;
    transition: max-height 0.2s ease-in-out;
}

.hidden {
    max-height: 0px !important;
}

.extend_inner_container {
    padding: 2em;
}

.edit_btn {
    background-color: #4421BB !important;
}

.delete_btn {
    background-color: red !important;
}

.row_first_dv {
    display: flex;
    gap: 0.5rem;
    /* position: relative; */
    align-items: center;
    cursor: pointer;
}

.action_button {
    display: flex;
    justify-content: center;
    gap: 1rem;
}