.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 6px;
    background: #FFF;
    padding: 1rem;
    padding-bottom: 5rem;
    overflow-x: auto;
}

.table_header {
    display: grid;
    /* grid-template-columns: 0.3fr repeat(5, 1fr); */
    grid-template-columns: repeat(5, 1fr);

    gap: 0.5rem;
    min-width: 700px;
}

/* 
.table_header>div:not(:first-child) {
    text-align: center;
} */

.label {
    color: var(--primary-text);
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
}

.table_rows_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 700px;
}

@media screen and (max-width:900px) {
    .label {
        font-size: 10px;
    }

    .icon_btn {
        height: 32px !important;
        width: 32px !important;
    }
}

@media screen and (max-width: 767px) {
    .label {
        font-size: 9px;
    }

    .table_rows_container {
        gap: 0.7rem;
    }

    .icon_btn {
        height: 25px !important;
        width: 25px !important;
    }
}