.card {
    background-color: white;
}

@media screen and (max-width:1000px) {
    .card {
        width: 100%;
    }
}

.card_header {
    padding: 1em 1.5em;
    background: rgba(68, 49, 184, 0.08);
}

.card_title {
    color: #252525;
    font-size: clamp(14px, 2.5vw, 17.415px);
    font-weight: 500;
}

.required_status {
    color: #FD3246;
    font-size: 7.74px;
    padding: 3.87px 10.643px;
    border-radius: 7.74px;
    background: rgba(253, 50, 70, 0.18);
}

.add_button {
    width: clamp(15px, 2.5vw, 25px);
    height: clamp(15px, 2.52vw, 25px);
    background-color: rgba(68, 49, 184, 1) !important;
}

.card_desc {
    color: #5A6470;
    font-size: clamp(10px, 2.5vw, 14px);
}