.modalContentContainer {
  /* min-height: 60vh;
  max-height: 60vh; */
  min-height: 670px;
}

.routeDetailContainer {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  /* padding: 20px; */
  margin: 0 0 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.routeInnerContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.routeflexContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.labelContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.labelHeading {
  width: 30%;
  margin: 0;
  font-size: 12px;
}
.labelDescription {
  width: 70%;
  margin: 0;
  font-size: 12px;
}

@media screen and (max-width: 1200px) {
  .labelHeading {
    width: 50%;
  }
  .labelDescription {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  .routeInnerContainer {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  .labelHeading {
    width: 100%;
  }
  .labelDescription {
    width: 100%;
  }
}
