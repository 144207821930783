.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1500;
}

.inner_container {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.main {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 120px);
  /* overflow-y: auto; */
  padding: 2rem;
  border-radius: 8px;
  background-color: white;
  position: relative;
  scrollbar-width: thin;
  width: 90%;
}

@media screen and (max-width: 700px) {
  .main {
    width: 98%;
  }
}

.main > div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.close_btn {
  /* height: 25px !important; */
  /* width: 25px !important; */
  position: absolute !important;
  top: 0.5rem !important;
  right: 0.5rem !important;
  background-color: #dbe7fc !important;
  box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
    0 0.126rem 0.225rem rgba(122, 123, 151, 0.3) !important;
  padding: 5px !important;
}

.edit_btn {
  /* height: 25px !important;
    width: 25px !important; */
  position: absolute !important;
  top: 0.5rem !important;
  right: 3rem !important;
  background-color: #dbe7fc !important;
  box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
    0 0.126rem 0.225rem rgba(122, 123, 151, 0.3) !important;
  padding: 5px !important;
}

.close_btn:hover,
.edit_btn:hover {
  background-color: #acc8f8 !important;
}
