.main {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: #fff;
  padding: 1.5rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.containerTable {
  overflow-x: auto;
  padding: 0 0.2rem;
}
.cointainerHeading {
  display: grid;
  align-items: center;
  /* justify-content: center; */
  padding: 1rem 0;
  width: 100%;
  /* grid-template-columns: 0.5fr 0.5fr repeat(3, 1fr) 0.5fr repeat(3, 1fr) 0.5fr; */
  gap: 1rem;
  border-top: 2px solid rgba(149, 149, 149, 0.15);
  border-bottom: 2px solid rgba(149, 149, 149, 0.15);
}

.cointainerHeadingText {
  text-align: center;
  background: rgba(59, 68, 177, 0.06);
  /* width: 100%; */
  min-width: 250px;
}
.cointainerHeading > span {
  color: var(--primary-text);
  font-size: 16px;
  font-weight: 600;
  padding: 1rem;
}

.containerBody {
  width: 100%;
  display: grid;
  align-items: center;
  /* grid-template-columns: 0.5fr 0.5fr repeat(3, 1fr) 0.5fr repeat(3, 1fr) 0.5fr; */
  gap: 1rem;
  border-bottom: 2px solid rgba(149, 149, 149, 0.15);
}
.cointainerBodyText {
  color: rgba(105, 105, 105, 1);
  padding: 20px 0;
}

.tableMainHeadingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}


@media screen and (max-width: 1300px) {
  .cointainerHeading{
    width: max-content;
  }
  .containerBody{
    width: max-content;
  }
}