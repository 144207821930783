.container {
    padding: 0.7rem;
    border-radius: 4.166px;
    background: #FFF;
}

.top_header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
}

.detail_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem 1.5rem;
}

.last_updated {
    color: var(--primary-text);
    font-size: 9px;
    font-weight: 600;
}

.request_id_value {
    color: var(--primary-text);
    font-size: 8.333px;
    font-weight: 500;
}

.request_id {
    color: var(--secondary-text);
    font-size: 8.333px;
    font-weight: 500;
}

.last_update_date {
    color: var(--secondary-text);
    font-size: 7px;
    font-weight: 500;
}


.bottom {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.status {
    padding: 5px 8px;
    text-align: center;
    color: #FFF;
    font-size: 7px;
    font-weight: 600;
    border-radius: 1.204px;
    background: var(--primary);
}