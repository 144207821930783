@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap');

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background: #F4F7F7;
    padding: 2rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.form_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 32px;
    background: #FBFCFC;
    padding: 2rem;
    max-width: 440px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form_legend {
    color: #202020;
    font-family: Poppins;
    font-size: 31px;
    font-weight: 500;
    line-height: 48px;
    /* 133.333% */
}

.form_desc {
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
}

.input {
    padding-top: 0;
    padding-bottom: 0;
    background: #FFF !important;
    border-radius: 12px !important;
}

.input :global .MuiOutlinedInput-notchedOutline {
    border-radius: 12px !important;
    border: 1px solid #DCDBDD !important;
}


.form_actions_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.signin_btn {
    border-radius: 12px !important;
    padding: 0.7rem 3rem !important;
}


.caption {
    color: #84818A;
    font-family: Poppins;
    font-size: 12px;
    line-height: 17px;
    /* 141.667% */
}

.caption a {
    color: #4431B8;
}