  
  .bannerImage {
    width: 150px;
    height: auto;
    object-fit: contain;
  }
  
  .bannerContainer {
    display: flex;
    flex-direction: column;
    /* box-shadow: 0px 4px 40px 0px rgba(68, 49, 184, 0.12); */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 10px;
    align-items: center;
    margin: 1rem 0;
    padding: 2rem;
    width: 100%;
  }
  
  .bannerContainer > span{
      text-align: center;
  }
  