.rating {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0.5rem 1rem;
}

.rating_label {
    font-size: 14px;
    color: var(--secondary-text);
}

.rating_value {
    color: var(--primary-text);
    white-space: normal;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}


.review_box {
    padding: 1rem;
    line-height: 1.7;
    font-size: 14px;
    color: var(--primary-text);
    white-space: normal;
    border: 2px solid var(--gray);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}












/* pagination css overide  */
.pagination :global .Mui-selected {
    background-color: var(--primary) !important;
    color: #fff !important;
    box-shadow: none !important;
}

.pagination :global .Mui-selected:hover {
    background-color: var(--primary) !important;
    color: #fff !important;
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3) !important;
}

.pagination :global .MuiPaginationItem-root {
    border: 1px solid var(--color-divider) !important;
    border-radius: 7px !important;
}

.pagination :global .MuiPagination-ul {
    gap: 0.7rem;
}

@media screen and (max-width:767px) {
    .request_change_btn {
        font-size: 0.78rem !important;
    }
}