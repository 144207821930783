.container {
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    max-width: 552px;
    width: 60%;
    transition: all 0.3s linear;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

@media screen and (max-width:650px) {
    .container {
        max-width: 90%;
        width: 90%;
    }
}

.hidden {
    transform: translateX(110%);
}

.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    /* Adjust the opacity as needed */
    z-index: 1;
}

.hide_backdrop {
    width: 0;
}

.content {
    overflow-y: auto;
    height: 100%;
    overflow-anchor: none;
    position: fixed;
    top: 0;
    width: -webkit-fill-available;
    width: -moz-available;
}

.content::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

/* Optional: Customize the scrollbar track and thumb styles */
.content::-webkit-scrollbar-track {
    background-color: transparent;
}

.content::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.close_btn {
    width: 29.005px !important;
    height: 29.005px !important;
    background-color: rgb(119, 223, 255) !important;
    color: white !important;
    padding: 20px !important;
    border-radius: 50% !important;
    position: relative !important;
    left: 0 !important;
    top: 0.5em !important;
    translate: -50% !important;
    z-index: 10;
    box-shadow: rgba(4, 4, 4, 0.33) 0px 1px 15px !important;

}