.container {
    display: grid;
    grid-template-columns: max-content 1fr;
    padding: 1.5rem;
    border-radius: 16px;
    gap: 2rem;
    position: relative;
    background: rgba(252, 252, 255, 1);
    box-shadow: 0px 4px 32px 0px rgba(64, 64, 64, 0.06);

    /* box-shadow: 0px 4px 80px 0px rgba(64, 64, 64, 0.22); */
    /* margin: 2rem 0 0 2rem; */

}

@media screen and (max-width:700px) {
    .container {
        grid-template-columns: auto;
        /* margin: 1rem 0 0 1rem; */
    }
}

.left {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    /* gap: 2rem; */
    align-items: center;
}

.right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 4rem;
}

/* .rightInner{
    display: grid;
    grid-template-columns: 1fr 1fr;
} */

.rightInner{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.rightInner_heading{
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    width: 30%;
}

.rightInner_description{
    font-size: 14px;
    margin: 0;
    width: 70%;
}



/* @media screen and (max-width: 1200px) {
    .right {
        padding-left: 1rem;
    }
  } */
  
  @media screen and (max-width:700px) {
    .container {
        grid-template-columns: auto;
        padding: 1rem;
    }
    .right {
        padding-left: 0rem;
        gap: 1rem;
    }
    .rightInner_heading{
        width: 100%;
    }
    .rightInner_description{
        width: 100%;
    }
}

























.user_box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.user_avatar {
    position: relative;
    border-radius: 50%;
}

.user_avatar>img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

.avatar_check {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    height: 23px;
    width: 23px;
    background-color: var(--success);
    border-radius: 50%;
}

.user_name_box {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
}

.user_name {
    color: var(--primary-text);
    /* font-family: Poppins; */
    font-size: 16px;
    font-weight: 500;
}

.gray_text {
    color: var(--secondary-text);
    /* font-family: Poppins; */
    font-size: 12px;
}

.data_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    border-radius: 12px;
}

.data_box>span {
    font-size: 12px;
    font-family: Poppins;
}

.data_box>strong {
    font-size: 12px;
    font-family: Poppins;
}

.shipment_data {
    color: var(--primary);
    border: 1px solid var(--primary);
    background: #ECE9FF;
}

.shippers_data {
    border: 1px solid #4E98FE;
    background: #E3EFFF;
}


.contact_container {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.contact_container>div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.contact_container>div>span {
    color: var(--primary-text);
    font-family: Poppins;
    font-size: 12px;
}