.container {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.container>div {
    flex: 1;
    color: #777;
    font-size: 12px;
    padding: 0.3rem;
}