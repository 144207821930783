.container {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    min-width: 800px;
}

.head_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.title {
    color: #363636;
    font-size: 18px;
    font-weight: 500;
}


.table_header {
    display: flex;
    gap: 0.3rem;
}

.table_header>div {
    flex: 1;
    color: #595959;
    font-size: 14px;
    font-weight: 500;
    padding: 0.3rem;
}

.sub_total_container {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
    margin-top: 0.6rem;
}

.bold_bottom_table {
    color: #363636;
    font-size: 16px;
    font-weight: 500;
}

.sub_total_price {
    color: #363636;
    font-size: 16px;
    font-weight: 500;
    padding: 0 2rem;
}