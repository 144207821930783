.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 2rem;
}

@media screen and (max-width:1150px) {
    .container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width:760px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width:500px) {
    .container {
        grid-template-columns: repeat(1, 1fr);
    }
}