.container {
    position: absolute;
    top: 1.7rem;
    right: 0;
    max-width: 300px;
    width: max-content;
    scale: 1;
    border-radius: 10px;
    overflow: hidden;
    z-index: 12;
    transform-origin: top center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    transition: all 0.2s ease-in-out;
}

.collapse {
    /* max-width: 0;
    max-height: 0; */
    scale: 0;
    border-radius: 50%;
}

.main {
    padding: 1.5rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media screen and (max-width:700px) {
    .container {
        max-width: 280px;
    }
}