.card {
  margin: 1rem 0;
  height: 135px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 250px;
  border-radius: 6px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.clickBtn {
  padding: 0.1rem 1rem !important;
  color: #fff !important;
  border-radius: 6px !important;
  background-color: rgba(49, 56, 110, 1) !important;
}
