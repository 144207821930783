.main {
    margin-block: 2rem;
}


.image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}

.image_container::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    transition: opacity 0.2s ease-in;
}

.preview_btn {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 2 !important;
    opacity: 0 !important;
}

.image_container:hover .preview_btn,
.image_container:hover::before {
    opacity: 1 !important;
}

.image {
    height: 100px;
    width: 100px;
}