.containerClass :global .form-control{
    width: 100% !important;
    height: 40px !important;
}

/* .dropdownClass :global .react-tel-input .country-list {
    position: relative !important;
    width: 100%;
} */

.containerClass :global .MuiOutlinedInput-notchedOutline {
    border: "1px solid #dcdbdd !important"
  }