.dimension-input-group {
  .MuiFormControl-root {
    .MuiOutlinedInput-notchedOutline {
      @include border-radius(0);
    }

    &:first-child {
      .MuiOutlinedInput-notchedOutline {
        @include border-left-radius($border-radius-xs);
      }
    }

    &:last-child {
      max-width: 80px;
      .MuiOutlinedInput-notchedOutline {
        @include border-right-radius($border-radius-xs);
      }
    }
  }

  .Mui-disabled {
    cursor: not-allowed;
  }
}
