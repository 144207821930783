.outer_container,
.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* top .container  */
.top_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
}

.from_to {
    display: flex;
    gap: 1rem;
}

.from_to_arrow {
    position: relative;
}

.from_to_arrow::before {
    content: '';
    position: absolute;
    width: 32px;
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.214);
}

.request_id {
    display: flex;
    gap: 1rem;
}

.request_id>span {
    font-size: clamp(11px, 2vw, 14px);
}

/* middle container  */

.middle_container {
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: space-between;
}


.middle_details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
}

.middle_details_label {
    color: var(--secondary-text);
    font-size: clamp(9px, 1.5vw, 11.7px);
    font-weight: 500;
    text-align: left;
}

.middle_details_value {
    color: var(--primary-text);
    font-size: clamp(8px, 1.3vw, 10px);
    font-weight: 500;
    text-align: left;
}

.container_detail {
    color: var(--secondary-text);
    font-size: clamp(8px, 1.5vw, 10.5px);
    font-weight: 500;
}


/* bottom container  */
.bottom_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    gap: 2rem;
    flex-wrap: wrap;
}

.bottom_container_grid {
    display: grid !important;
    grid-template-columns: 1fr max-content !important;
    align-items: end !important;
}

.bold {
    color: var(--primary-text);
    font-size: clamp(10px, 1.5vw, 13px);
    font-weight: 600;
}


/* bottom most  */
.bottom_most_container {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.price_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    padding: 1rem;
    border-radius: 2.758px;
    background: rgba(68, 49, 184, 0.08);
}

.label_container {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.label_container>span {
    color: var(--secondary-text);
    font-size: clamp(10px, 2vw, 12px);
}

.price_details {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.price_value {
    color: var(--primary-text);
    font-size: clamp(10px, 2vw, 12px);
}

.action_container {
    display: flex;
    gap: 2rem;
    align-items: end;
    margin-bottom: 1rem;
}

/* when canceled show this styles on all container with 'shows-cancel-error' */
.show_cancel_styles :global .shows-cancel-error {
    color: var(--danger) !important;
}

.error_message>div {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    font-size: xx-small;
    padding: 0.2rem 0.7rem;
    border-radius: 15.84px;
    background: rgba(253, 50, 70, 0.12);
}


@media screen and (max-width:700px) {
    .avatar {
        width: 20px !important;
        height: 20px !important;
    }

    .top_container {
        gap: 1rem;
        flex-wrap: wrap;
    }

    .middle_container {
        flex-direction: column;
        align-items: start;
        gap: 1rem;
    }

    .bottom_most_container {
        flex-direction: column;
        gap: 1rem;
    }

    .action_container {
        justify-content: end;
        gap: 1rem;
    }

    .action_btn {
        padding: 4px 8px !important;
        font-size: xx-small !important;
    }
}