.container {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    padding: 1rem;
    border-radius: 10.473px;
    border: 0.873px solid #F4F4F3;
}

.user_role {
    color: var(--primary-text);
    font-family: Poppins;
    font-size: 8px;
}

.user_name {
    color: var(--secondary-text);
    font-family: Poppins;
    font-size: 6px;
}