//  Variables

.app-sidebar {
  // width: $sidebar-width;
  flex-direction: column;
  display: flex;
  height: 100%;
  transition: all 0.5s $app-sidebar-transition;
  z-index: 10;
  position: relative;

  @include media-breakpoint-up(lg) {
    &--shadow {
      box-shadow: $sidebar-shadow;
    }
  }

  &--content {
    overflow-y: auto;
    overflow-x: visible;
    height: auto;
  }

  &--collapsed {
    // width: $sidebar-width-collapsed;

    .app-sidebar--header {
      justify-content: center;
    }
  }
}

// Fixed sidebar

.app-sidebar-fixed {
  .app-sidebar {
    // position: fixed;
    // left: 0;

    &--content {
      height: 100%;
    }
  }
}

.app-sidebar-overlay {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(7, 9, 25, 0.4);
  z-index: 10;

  @include media-breakpoint-down(lg) {
    &.is-active {
      opacity: 1;
      visibility: visible;
      width: 100%;
      height: 100%;
    }
  }
}

//For inner pages sidebar - using Material Drawer
.inner-page-sidebar {
  width: $sidebar-width !important;
  min-width: $sidebar-width !important;

  &>.MuiDrawer-paper {
    width: $sidebar-width !important;
    min-width: $sidebar-width !important;
    padding-top: $header-height !important;
  }

  &>.MuiDrawer-paperAnchorLeft {
    left: auto !important;
    border-right: 0 !important;
  }

  &>.MuiDrawer-paperAnchorRight {
    right: auto !important;
    border-left: 0 !important;
  }
}

.inner-page-sidebar-md {
  width: $sidebar-width-md !important;
  min-width: $sidebar-width-md !important;

  &>.MuiDrawer-paper {
    width: $sidebar-width-md !important;
    min-width: $sidebar-width-md !important;
    padding-top: $header-height !important;
  }

  &>.MuiDrawer-paperAnchorLeft {
    left: auto !important;
    border-right: 0 !important;
  }

  &>.MuiDrawer-paperAnchorRight {
    right: auto !important;
    border-left: 0 !important;
  }
}

.inner-page-sidebar-lg {
  width: $sidebar-width-lg !important;
  min-width: $sidebar-width-lg !important;

  &>.MuiDrawer-paper {
    width: $sidebar-width-lg !important;
    min-width: $sidebar-width-lg !important;
    padding-top: $header-height !important;
  }

  &>.MuiDrawer-paperAnchorLeft {
    left: auto !important;
    border-right: 0 !important;
  }

  &>.MuiDrawer-paperAnchorRight {
    right: auto !important;
    border-left: 0 !important;
  }
}

.inner-page-sidebar-xl {
  width: $sidebar-width-xl !important;
  min-width: $sidebar-width-xl !important;

  &>.MuiDrawer-paper {
    width: $sidebar-width-xl !important;
    min-width: $sidebar-width-xl !important;
    padding-top: $header-height !important;
  }

  &>.MuiDrawer-paperAnchorLeft {
    left: auto !important;
    border-right: 0 !important;
  }

  &>.MuiDrawer-paperAnchorRight {
    right: auto !important;
    border-left: 0 !important;
  }
}

.chat-sidebar-top {
  &>.MuiDrawer-paper {
    padding-top: $header-height !important;
  }
}

// Responsive

@include media-breakpoint-down(lg) {
  .app-sidebar {
    // position: fixed !important;
    // left: -$sidebar-width !important;
  }

  .app-sidebar-mobile-open {
    .app-sidebar {
      // left: 0 !important;
      // box-shadow: $sidebar-shadow;
    }
  }
}

// Modifiers

@import 'modifiers/collapsed';

// Themes

@import 'themes/dark';
@import 'themes/light';