.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
}

.detail_container {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.dates_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.date_field_container {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.date_field_container>span {
    color: var(--secondary-text);
    font-size: 10px;
}

.date_field_container>input {
    color: var(--secondary-text);
    font-size: 10px;
    padding: 5px;
    max-width: 200px;
}

.management_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 10.473px;
    background: #FFF;
    box-shadow: 0px 1.7454545497894287px 41.890907287597656px 0px rgba(0, 0, 0, 0.04);
    padding: 1rem;
}

.manage_in_container {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.magement_title {
    color: var(--secondary-text);
    font-family: Poppins;
    font-size: 10.473px;
    text-transform: uppercase;
}

.add_service_btn {
    text-align: center !important;
    font-family: Poppins !important;
    font-size: 10.473px !important;
    color: var(--primary) !important;
    border-radius: 10.473px !important;
    border: 0.873px dashed var(--primary) !important;
}


.add_member_btn {
    border: 0.873px dashed #4E98FE !important;
    border-radius: 50% !important;
    padding: .7rem !important;
}