.kargo_text {
    color: #FFF;
    font-family: Inter;
    font-size: clamp(20px, 2vw, 25.077px);
    font-weight: 400;
}

.plex_text {
    color: #FFF;
    font-family: Inter;
    font-size: clamp(20px, 2vw, 25.077px);
    font-weight: 700;
}

.text_bold_big {
    /* color: #202020;
    font-family: Poppins;
    font-size: clamp(22px, 3vw, 32px);
    font-weight: 500;
    line-height: clamp(32px, 4vw, 48px);
    text-align: center; */
    color: rgba(255, 255, 255, 0.80);
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
}