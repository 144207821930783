.passwordContainer {
  color: black !important;
  border-radius: 0.25rem !important;
  padding: 0.5rem !important;
}
.passwordContainer > li {
  display: flex;
  gap: 0.75rem;
}
.passwordContainer > li > span {
  opacity: 1 !important;
  font-size: 14px !important;
}
