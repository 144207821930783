.container {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.label {
    color: var(--primary-text);
    text-transform: capitalize;
    font-family: Inter, Roboto, sans-serif;
    font-size: 13.418px;
    font-weight: 600;
}

.input :global .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.input {
    border-radius: 3.355px !important;
    background: #F6F7FB !important;
}

.input :global .MuiOutlinedInput-input {
    padding: 12px 12px !important;
}

@media screen and (max-width:1100px) {
    .label {
        font-size: 11px;
    }
}

@media screen and (max-width:700px) {
    .label {
        font-size: 9px;
    }
}