.container {
    display: flex;
    flex-direction: column;
    padding: 0.3rem;
    gap: 1rem;
    background-color: #fff;
    min-height: 100vh;
}

.header {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.route_icon {
    height: 12px;
    margin: 0 7px;
}

.status_btn {
    font-size: 10px !important;
    padding: 3px 6px !important;
}

.tabs_container {
    display: flex;
    gap: 0.7rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
}

.active_tab_btn {
    border-radius: 8px !important;
    width: 44px !important;
    height: 44px !important;
}

.tab_btn {
    border-radius: 8px !important;
    background: #F3F3F3 !important;
    width: 44px !important;
    height: 44px !important;
}

.action_btn {
    font-size: 8.9px !important;
}