.container {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    background: rgba(219, 231, 252, 0.25);
}

.city_container {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0.5rem 1rem;
}

.label {
    color: #06113C;
    font-size: clamp(14px, 2vw, 18px);
    font-weight: 800;
}

.input {
    border: none;
    background-color: transparent;
    font-size: clamp(12px, 1.5vw, 15px);
    width: 100%;
}

.swap_btn {
    position: absolute !important;
    right: 0 !important;
    top: 50% !important;
    transform: translate(50%, -50%) !important;
    background-color: white !important;
    box-shadow: 1px 2px 5px rgba(128, 128, 128, 0.267) !important;
    width: 25px !important;
    height: 25px !important;
}