.search_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

@media screen and (max-width: 700px) {
    .search_bar {
      flex-wrap: wrap;
    }
  }
  