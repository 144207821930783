.container {
    display: grid;
    min-height: 100vh;
    width: 100%;
    grid-template-columns: 1fr 1fr;
}


.container>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.left_container {
    background: #edebf8;
    padding: 2rem 3.5rem;
}

.right_container {
    background: #e6e3f5;
    gap: 1.5rem;
    flex-direction: column;
    align-items: start !important;
    padding: 2rem 3.5rem;
}

.form_container {
    max-width: 478px;
    padding: 2rem 3rem;
    border-radius: 28.201px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media screen and (max-width:900px) {
    .container {
        grid-template-columns: auto;
    }

    .left_container {
        grid-row: 2;
        padding: 1.5rem;
    }

    .right_container {
        grid-row: 1;
        padding: 1rem 2rem;
    }

    .form_container {
        padding: 2rem;
    }
}



.form_legend {
    color: #202020;
    font-family: Poppins;
    font-size: 28.201px;
    font-weight: 500;
    line-height: 150%;
    /* 150% */
}

.kargo_text {
    color: #727272;
}

.desc {
    font-family: Poppins;
    font-size: 12.338px;
    font-weight: 500;

}

.desc_question {
    color: #202020;
}

.desc_action {
    color: #4431B8;
    cursor: pointer;
}

.desc_action:hover {
    color: blue;
}

.name_fields_container {
    display: flex;
    gap: 1.5rem;
}

.warning_text,
.checkbox_label {
    color: #84818A;
    font-family: Poppins;
    font-size: 10.575px;
    line-height: 141%;
    /* 141.667% */
}

.form_submit_btn {
    border-radius: 12px !important;
    background-color: #4431B8 !important;
    text-transform: none !important;
    padding: 0.6rem 1.7rem !important;
}

.form_submit_btn:disabled {
    background-color: #4431B8 !important;
    color: white !important;
    cursor: not-allowed !important;
    opacity: 0.9;
}

/* right container text  */
.text_heading {
    color: #121212;
    font-family: Poppins;
    font-size: clamp(25px, 3.5vw, 48px);
    font-weight: 600;
    line-height: 120%;
    /* 120.833% */
}

.text_desc {
    color: #121212;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
}

.text_list {
    padding: 0 !important;
    padding-left: 1rem !important;
}

.text_list li {
    color: #121212;
    font-family: Poppins;
    font-size: 14px;
}

.bottom_text_desc {
    color: #121212;
    font-family: Poppins;
    font-size: 14px;
    line-height: 171%;
    /* 171.429% */
}