.main {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    background-color: white;
    padding: 1rem 0;
    border-radius: 7px;
}

.option {
    padding: 0.3rem 2rem 0.3rem 1rem;
    border-right: 3.216px solid white;
    transition: all 0.2s linear;
    cursor: pointer;
}

.option:hover {
    border-right: 3.216px solid #1890FF !important;
    background: #E6F7FF !important;
}

.active_option {
    border-right: 3.216px solid #1890FF;
    background: #E6F7FF;
}