.main {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: #fff;
  padding: 1.5rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.containerTable{
  
  overflow-x: auto;
}
.cointainerHeading {
  display: grid;
  align-items: center;
  width: 100%;
  /* grid-template-columns: 0.5fr 0.5fr repeat(3, 1fr) 0.5fr repeat(3, 1fr) 0.5fr; */
  gap: 0.5rem;
  border-top: 2px solid rgba(149, 149, 149, 0.15);
  border-bottom: 2px solid rgba(149, 149, 149, 0.15);
}

.cointainerHeading > span {
  color: var(--primary-text);
  font-size: 16px;
  font-weight: 600;
  min-width: 100px;
  padding: 20px 0;
}

.containerBody {
  width: 100%;
  display: grid;
  align-items: center;
  /* grid-template-columns: 0.5fr 0.5fr repeat(3, 1fr) 0.5fr repeat(3, 1fr) 0.5fr; */
  gap: 0.5rem;
  border-bottom: 2px solid rgba(149, 149, 149, 0.15);
}
.cointainerBodyText {
  color: rgba(105, 105, 105, 1);
  min-width: 100px;
  margin: 20px 0;
}

/* 
.table {
  height: 100%;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.thead tr {
  border-top: 2px solid rgba(149, 149, 149, 0.15);
  border-bottom: 2px solid rgba(149, 149, 149, 0.15);
}

.thead tr td {
  color: var(--primary-text);
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0;
}

.tbody tr {
  border-bottom: 2px solid rgba(149, 149, 149, 0.15);
}
.tbody tr td {
  color: rgba(105, 105, 105, 1);
  padding: 20px 0;
}

@media screen and (max-width: 1200px) {
  .table {
    overflow-x: scroll !important;
  }
}

@media screen and (max-width: 900px) {
  .table {
    overflow: scroll !important;
  }
}
 */

 /* @media screen and (max-width: 1300px) {
  .cointainerHeading{
    width: max-content;
  }
  .containerBody{
    width: max-content;
  }
} */

.tableMainHeadingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
