.container {
    padding: 1rem 2rem;
    border-radius: 2px;
    background: rgba(68, 49, 184, 0.04);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.title {
    color: var(--primary-text);
    font-size: 14px;
    font-weight: 500;
}

.main {
    display: flex;
    gap: 0.5rem;
}

.icon_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;
}

.input_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: space-around;
    position: relative;
}

.input {
    width: 100%;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid #A9A9A9;
    padding: 5px 10px;
    font-size: small;

}

.swap_btn {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;

}