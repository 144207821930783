.container {
    /* display: flex; */
    /* flex-direction: column; */
    /* gap: 1.5rem; */
    border-radius:16px;
    background: rgba(252, 252, 255, 1);
    box-shadow: 0px 4px 32px 0px rgba(64, 64, 64, 0.06);
    max-width: 100%;

}

.main {
    padding: 1rem 1.5rem;
    max-height: 255px;
    min-height: 255px;
    overflow-y: auto;
}

@media screen and (max-width:1040px) {
    .container {
        max-width: 100%;
    }
}

@media screen and (max-width:600px) {
    /* .container { */
        /* margin: 1rem 1rem 1rem 0; */
    /* } */
}