.card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    /* width: 200px; */
    border-radius: 17.5px;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(68, 49, 184, 0.10), -1.4583333730697632px 2.9166667461395264px 7.2916669845581055px 0px rgba(68, 49, 184, 0.10), -5.833333492279053px 13.125px 14.583333969116211px 0px rgba(68, 49, 184, 0.09), -11.666666984558105px 29.166667938232422px 18.95833396911621px 0px rgba(68, 49, 184, 0.05), -20.416667938232422px 51.04166793823242px 21.875px 0px rgba(68, 49, 184, 0.01), -32.083335876464844px 80.20833587646484px 24.791667938232422px 0px rgba(68, 49, 184, 0.00);
    transition: all 0.2s ease-in;
}

.card_active_state {
    /* background: #A799FD; */
    /* background-image: linear-gradient(135deg, #06113c 10%, #123597 100%); */
    background-image: linear-gradient(135deg, #443ab6 10%, #8cabff 100%);
}

.action_container {
    display: grid;
    align-items: start;
    grid-template-columns: max-content max-content;
    gap: 1rem;
    justify-content: space-between;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.price_field_container {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.price_field {
    background-color: white !important;
    border-radius: 3.355px !important;
}

.price_field_container .price_field :global .MuiOutlinedInput-input {
    padding: 6px 10px !important;
}

.card .price_field :global .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--primary) !important;
}

.card_active_state .price_field :global .MuiOutlinedInput-notchedOutline {
    border: 1px solid white !important;
}

.default_text_color {
    color: var(--primary-text);
}

.active_text_color {
    color: #fff;
}

.label {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}

.helper_text {
    font-size: 8px;
}


.switch :global .Mui-checked .MuiSwitch-thumb {
    background: var(--primary) !important;
}