.container {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    min-width: 800px;
}

.head_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.title {
    color: #363636;
    font-size: 18px;
    font-weight: 500;
}

.edit_table_btn {
    background-color: #4431bb !important;
    width: 22px !important;
    height: 22px !important;
    color: white !important;
}

.btn_container {
    display: flex;
    gap: 1rem;
}

.add_btn {
    background: #4431bb !important;
    /* text-transform: none !important; */
    color: white !important;
    box-shadow: none !important;
}

.delete_btn {
    background-color: red !important;
    /* text-transform: none !important; */
    color: white !important;
    box-shadow: none !important;
}

.add_btn:disabled {
    background-color: #4431bb !important;
    opacity: 0.5 !important;
    color: white !important;
}

.table_header {
    display: flex;
    gap: 0.3rem;
}

.table_header>div {
    flex: 1;
    color: #595959;
    font-size: 14px;
    font-weight: 500;
    padding: 0.3rem;
}

.sub_total_container {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
    margin-top: 0.6rem;
}

.bold_bottom_table {
    color: #363636;
    font-size: 16px;
    font-weight: 500;
}

.sub_total_price {
    color: #363636;
    font-size: 16px;
    font-weight: 500;
    padding: 0 2rem;
}