/* .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 1rem;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #fff;
    
    width: max-content;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    scrollbar-width: thin;
}

.header {
    color: var(--primary-text);
    font-size: 19.254px;
    font-weight: 500;
    background: rgba(68, 49, 184, 0.08);
    padding: 1rem 1.5rem;
    text-transform: uppercase;
}

.form_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
}

.grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    gap: 1.5rem;
}

.select_code {
    width: 70px !important;
}

.select_code :global .Mui-focused {
    box-shadow: none !important;
}

.phone_number_field :global .MuiOutlinedInput-adornedStart {
    padding-left: 0 !important;
}

.phone_number_field .select_code :global .MuiOutlinedInput-notchedOutline {
    border: none !important;
} */

.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* background-color: #fff;
    width: max-content;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    scrollbar-width: thin; */
}

.header {
  color: var(--primary-text);
  font-size: 19.254px;
  font-weight: 500;
  background: rgba(68, 49, 184, 0.08);
  padding: 1rem 1.5rem;
  text-transform: uppercase;
}

.form_container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  /* padding: 1.5rem; */
}

.grid_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  gap: 1.5rem;
}

.select_code {
  width: 70px !important;
}

.select_code :global .Mui-focused {
  box-shadow: none !important;
}

.phone_number_field :global .MuiOutlinedInput-adornedStart {
  padding-left: 0 !important;
}

.phone_number_field .select_code :global .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
