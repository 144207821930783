.activity_title {
    white-space: normal;
    font-size: 14px;
}

@media screen and (max-width:767px) {
    .activity_title {
        font-size: 0.7rem !important;
    }

    .avatar {
        height: 21px !important;
        width: 21px !important;
        border-radius: 50%;
    }

    .user_name {
        font-size: 0.6rem !important;
    }

    .date_time {
        font-size: 0.5rem !important;
    }

}