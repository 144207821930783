.container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 16;
    opacity: 1;
    overflow: hidden;
    transition: opacity 0.2s ease-in;
}

.collapse {
    height: 0 !important;
    width: 0 !important;
    opacity: 0 !important;
}

.backdrop {
    background: rgba(0, 0, 0, 0.3);
}


/* mui popup */
.noStyle :global .MuiPaper-elevation24 {
    box-shadow: none !important;
}

.noStyle :global .MuiDialogContent-dividers,
.noStyle :global .MuiDialogContent-root:first-child,
.noStyle :global .MuiDialogContent-root {
    padding: 0 !important;
}

.noStyle :global .MuiDialogContent-dividers {
    border: none !important;
}