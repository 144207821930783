.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.label {
    color: var(--primary-text);
    font-family: Inter, Roboto, sans-serif;
    font-size: 13.418px;
    font-weight: 600;
}

.canvas {
    width: 393px;
    height: 100px;
    border-radius: 3.355px;
    background: #F6F7FB;
}