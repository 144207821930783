.container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* padding: 2rem 1.5rem 0 1.5rem; */
    padding: 2rem 3rem 0 3rem;
}

.seach_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* padding: 0 1.5rem; */
    padding: 0 3rem;
}

.search_bar {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    /* padding: 0 1rem; */
}

.search_btn {
    border-radius: 7.968px !important;
}

.search_field :global .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.search_field {
    font-size: 11.5px !important;
    border-radius: 13.28px !important;
    background: #F4F2FF !important;
}

.search_field :global .MuiOutlinedInput-input {
    padding: 10px 12px !important;
}

.edit_actions_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 1px;
    background: #D9D9D9;
    padding: 2px;
}

.main_actions_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.seach_container>span {
    font-size: 11px;
    color: var(--secondary-text);

}

.search_input :global .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.search_input {
    border-radius: 2px;
    background: #E8E8E8;
    font-size: 11px !important;
}

.search_input :global .MuiOutlinedInput-input {
    padding: 7px !important;
}