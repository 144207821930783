.page_title {}

@media screen and (max-width: 767px) {
    .page_title h1 {
        font-size: 17px !important;
    }

    .page_title_desc {
        font-size: 11px !important;
    }
}