.header_button{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
}

.service_info_container{
 background: #fff;
 margin: 0 0 20px 0;
 padding: 20px;
 position: relative;
 width:100%;
 border-radius: 10px;
}

.icon_background{
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #4431BB;
    width:50px;
    height: 50px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    border-radius: 10px;
}

.Service_name{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1rem;
}

.description_container{
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}
.heading{
    width: 250px;
    color: rgba(112, 112, 112, 1);
    font-size: 14px;
}
.description{
    width: 100%;
    color: rgba(112, 112, 112, 1);
    font-size: 14px;
}