.grid_container,
.inner_grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 4rem;
    align-items: end;
}

.flex_col_gap_1 {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.kind_no {
    display: flex;
    gap: 0.3rem;
    flex-direction: column;
}

.kind_no_field_container {
    display: grid;
    gap: 1rem;
    grid-template-columns: 0.7fr 1.3fr;
}

.label {
    color: var(--primary-text);
    text-transform: capitalize;
    font-family: Inter, Roboto, sans-serif;
    font-size: 13.418px;
    font-weight: 600;
}

@media screen and (max-width:1100px) {
    .main {
        padding: 1.5rem;

    }

    .right_side_bar {
        padding: 1.5rem;
    }

    .grid_container,
    .inner_grid_container {
        gap: 1rem 1.5rem;
    }

    .inner_grid_container {
        grid-template-columns: auto;
    }

    .label {
        font-size: 11px;
    }
}

@media screen and (max-width:700px) {
    .main {
        padding: 1rem;

    }

    .right_side_bar {
        padding: 1rem;
    }

    .grid_container,
    .inner_grid_container {
        gap: 1rem;
    }

    .label {
        font-size: 9px;
    }
}