// stylelint-disable declaration-no-important

// Common values
@each $position in $positions {
  .position-#{$position} {
    position: $position !important;
  }
}

// Shorthand

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.fixed-top-header {
  position: fixed;
  padding-top: $header-height !important;
}

.sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
  }
}

$num: 50;
@while $num < 200 {
  .sticky-sidebar-top-#{$num} {
    @supports (position: sticky) {
      position: sticky;
      top: $num + 0px;
      // z-index: $zindex-sticky;
    }
  }
  $num: $num + 50;
}
