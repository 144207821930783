.container {
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid white;
    cursor: pointer;
    transition: all 0.3s linear;
}

.container:hover,
.check_container {
    /* border: 1px solid var(--primary); */
    background: rgba(68, 49, 184, 0.12);
}

.container>label {
    color: var(--secondary-text);
    font-size: clamp(10px, 1.5vw, 14px);
}

.check_btn {
    height: 23px !important;
}