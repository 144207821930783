.main {
    padding: 1rem;
    border-radius: 2px;
    border: 0.55px solid #C9C9C9;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.action_container {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    background: rgba(68, 49, 184, 0.06);
    display: flex;
}

.actions {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;
}

@media screen and (max-width:700px) {
    .main {
        padding: 0.5rem;
    }

    .action_container {
        padding: 0.5rem;
    }
}