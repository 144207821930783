.notification_status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    color: white;
    border-radius: 50%;
    background: var(--primary);
    font-size: 12px;
}