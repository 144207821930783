.main {
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: 2rem;
  padding: 2rem;
}

.main_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container {
  border-radius: 10px;
  padding: 2rem 2rem;
  display: flex;
  gap: 20px;
  flex-direction: column;
  /* border: 1px solid black; */
  background-color: #fff;
}

.thumbNailImage {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.heroImage {
  min-width: 200px;
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.imageContainer {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 1000px) {
  .main {
    display: grid;
    grid-template-columns: 12fr;
    gap: 2rem;
    padding: 1rem;
  }
  .container {
    padding: 1rem;
  }
  .imageContainer {
    flex-direction: column;
  }
}
