//  Core

.app-page-title {
  // margin: (-$layout-spacer) (-$layout-spacer) ($layout-spacer / 1.5);
  padding: ($layout-spacer/1.5) $layout-spacer (
    $layout-spacer / 3
  );

@include media-breakpoint-down(sm) {
  // margin: (-($layout-spacer / 3)) (-($layout-spacer / 3)) ($layout-spacer / 3);
  padding: $layout-spacer / 3;
}

display: flex;
justify-content: space-between;
align-items: center;
background: rgba($white, 0.8);
transition: $transition-base;
// min-height: 136px; // Especially for fixed page titles - no overlapping. This and header has to = 210px

&--shadow {
  box-shadow: $page-title-shadow;
}

@include media-breakpoint-down(md) {
  // flex-direction: column;
}

&--dark {
  color: rgba($white, 1);
}

&--first {
  flex-grow: 1;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(md) {
    // flex-direction: column;
    // margin-bottom: ($spacer * 1.5);
  }
}

&--second {
  display: flex;
  align-items: center;
}

&--heading {
  padding-right: ($spacer);

  @include media-breakpoint-down(md) {
    // padding-right: 0;
    // text-align: center;
  }

  h1 {
    font-size: $display4-size / 1.1;
    font-weight: 700;
    margin: 0;
  }
}

&--iconbox {
  background: $white;
  @include border-radius($border-radius-sm);
  box-shadow: $box-shadow-sm-light;
  display: flex;
  margin: 0 ($spacer * 1.5) 0 0;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(md) {
    margin: 0 0 ($spacer * 1.5);
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

&--description {
  margin: ($spacer / 2) 0 0;
  // font-size: $font-size-base * 1.1;
  // opacity: .6;
  font-weight: normal;
}

// Alternate style 1

&.app-page-title-alt-1 {
  background: $white;
  padding-top: ($layout-spacer / 3);
  padding-bottom: ($layout-spacer / 3);

  .app-page-title--heading {
    display: flex;
    align-items: center;

    h1 {
      font-size: $font-size-xl;
      white-space: nowrap;
    }
  }

  .app-page-title--description {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 0 0 ($spacer / 2);
    font-size: $font-size-base;
  }

  .app-page-title--iconbox {
    display: none !important;
  }
}

// Alternate style 2

&.app-page-title-alt-2 {
  margin: (-$layout-spacer) 0 $layout-spacer;
  padding: $layout-spacer;
  @include border-bottom-radius($border-radius);
}

// Alternate style 3

&.app-page-title-alt-3 {
  margin: 0 0 $layout-spacer;
  padding: ($layout-spacer / 1.5) $layout-spacer;
  @include border-radius($border-radius);

  .app-page-title--iconbox {
    @include border-radius(100%);
  }
}

// Fixed Page Title
&--fixed {
  position: fixed;
  box-shadow: $header-shadow;
  background: $white;
  z-index: 1164; //because overlay on active shipment is set to 1165
}
}

.chat-header {
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-page-title-fixed {
  // position: fixed;
  // box-shadow: $header-shadow;
  background: $white;
  z-index: 1201; //because overlay on active shipment is set to 1165
  width: 100%;
  height: 72px;
  transition: all 0.2s ease-in-out;
}