.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    /* overflow: hidden; */
    overflow: auto;
    transition: width 0.2s ease-in;
    z-index: 20;
}

.collapse {
    width: 0;
}


.main {
    display: flex;
    flex-direction: column;
    /* gap: 2rem; */
    flex: 1;
    background-color: #06113c;
    padding: 0 5px 1rem 5px;
    position: relative;
}

.close_icon_container {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    padding: 1rem 1rem 0.5rem 0;
    background-color: #06113c;
    z-index: 1;
}

.drawer_logo {
    height: 30px;
    margin-left: 1rem;
}

.settings_container {
    display: flex;
    flex-direction: column;
}

.setting_list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.5rem;
    padding: 0 4.8rem;
}

.settingmenu_link {
    color: white !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 0.2rem 0 !important;
    font-size: small;
}

.settingmenu_link:hover {
    color: var(--primary) !important;
}