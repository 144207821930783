.container {
    display: grid;
    grid-template-columns: max-content 1fr;
    height: 100%;
    background-color: #fff;
}

.left_side_bar {
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
    gap: 1rem;
}


.tabs_container {
    display: flex;
    flex-direction: column;
    gap: 0.15rem;
}

.tab_label {
    color: var(--primary-text);
    font-size: 11px;
    padding: 6px 10px;
    position: relative;
    max-width: 140px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.tab_label::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: -1px;
    width: 3px;
    height: 65%;
    border-radius: 10px;
    background-color: var(--primary);
    visibility: hidden;
}

.tab_label:hover {
    color: var(--primary);
    background: rgba(68, 49, 184, 0.12);
}

.tab_label:hover::before {
    visibility: visible;
}

.active_tab {
    color: var(--primary);
    /* font-weight: 600; */
    background: rgba(68, 49, 184, 0.12);
}

.active_tab::before {
    visibility: visible;
}