.container {
    position: absolute;
    /* top: 2.5rem; */
    top: calc(100% + 5px);
    right: 2rem;
    opacity: 1;
    max-height: 400px;
    max-width: 300px;
    min-width: 150px;
    width: max-content;
    z-index: 12;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 0.2s ease-in;
}

.collapse {
    max-height: 0px !important;
    max-width: 0px !important;
    opacity: 0 !important;
}

.noStyle :global .MuiPaper-root {
    background-color: transparent !important;
}

.noStyle :global .MuiPaper-elevation8 {
    box-shadow: none !important;
}