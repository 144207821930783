.container {
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.lead_details {
    /* padding: 2rem 3rem 0rem 3rem; */
}



@media screen and (max-width:700px) {
    .lead_details {
        padding: 1rem;
    }
}
