.popup_container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #f4f5fd;
  z-index: 6;
}

/* .normal_container {} */

.normal_container > div {
  height: auto;
  overflow: auto;
}

.normal_container > div > div {
  margin-top: 0;
}

.hidden {
  display: none !important;
}

.overflow_container {
  overflow-y: auto;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  /* gap: 1.5rem; */
  margin-top: 74px;
  /* padding: 1rem 3rem; */
}

.main {
  display: flex;
  gap: 2rem;
  /* flex-wrap: wrap; */
  margin-bottom: 4rem;
}

.modalContentContainer {
  /* min-height: 60vh;
  max-height: 60vh; */
  min-height: 670px;
}

@media screen and (max-width: 700px) {
  /* .container {
        padding: 1rem;
    } */

  .top_bar_container {
    flex-direction: column !important;
  }
}

.nav_and_detail {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.back_btn {
  background: #4431bb !important;
  color: white !important;
  height: 28px !important;
  width: 28px !important;
}

.detail_text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 3rem;
  z-index: 10;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
@media screen and (max-width: 1200px) {
  .main {
    flex-wrap: wrap;
    margin-bottom: 7rem;
  }
}
.submitButton {
  width: 100% !important;
}
.cancelButton {
  width: 100% !important;
}
.submitButton:disabled {
  color: #4431bb !important;
}
@media screen and (max-width: 800px) {
  .footer {
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
    align-items: start;
  }
  .submitButton {
    width: 50% !important;
  }
  .cancelButton {
    width: 50% !important;
  }
  .footerBtnContainer {
    width: 100% !important;
  }
}
