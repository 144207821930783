.stepper :global .Mui-active,
.stepper :global .Mui-completed {
    color: var(--primary) !important;
}

.stepper :global .MuiStepContent-root {
    border-left: 1px solid var(--primary) !important;
}

.stepper :global MuiStepConnector-line {
    border-color: var(--primary) !important;
}

.btn {
    font-size: 0.833rem !important;
    padding: 0.3rem 0.7rem !important;
}

.label {
    color: var(--primary) !important;
    font-size: 0.9rem !important;
}

@media screen and (max-width:768px) {
    .btn {
        font-size: 8px !important;
        padding: 0.2rem 0.4rem !important;
    }

    .label {
        font-size: 0.7rem !important;
    }

}