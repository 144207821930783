.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 6px;
    background: #FFF;
    padding: 2rem 3rem;
    padding-bottom: 5rem;
}

.table_header {
    display: grid;
    grid-template-columns: 0.3fr repeat(4, 1fr);
    gap: 0.5rem;
}

.table_header>div:not(:first-child) {
    text-align: center;
}

.label {
    color: var(--primary-text);
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
}

.table_rows_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}