.card {
  border: 0.55px solid rgba(216, 216, 216, 1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  position: relative;
  min-width: 250px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.cardheadersection {
  background: rgba(68, 49, 184, 0.12);
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  border-radius: 6px 6px 0 0;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cardheadersection > p {
  margin: 0;
  font-size: 0.9rem;
  color: rgba(68, 49, 184, 1);
  font-weight: 500;
}

.status_chip {
  background: rgba(255, 255, 255, 1);
  font-size: 0.7rem !important;
  color: rgba(52, 168, 85, 1) !important;
  padding: 0.2rem 1rem;
  border-radius: 15px;
}

.transitTimeContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 1rem;
}
.transitTimeContainer > p {
  font-size: 0.8rem;
  margin: 0 0 0 1rem;
}
.transitTimeContainer > span {
  color: rgba(68, 49, 184, 1);
  font-size: 0.6rem;
  margin: 0 0 0 0.5rem;
}


.dateContainer{
    color: rgba(68, 49, 184, 1);
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.dateContainer > p {
    margin: 0;
}
.clickBtn{
    position: absolute !important;
    bottom: 0px !important;
    right: 0px !important;
    border-radius: 6px 0 6px 0 !important;
    background-color: rgba(49, 56, 110, 1) !important;
    border:none !important;
    padding: 0.4rem 0.8rem !important;
}