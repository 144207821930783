.drag_zone_text_container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.upload_text {
    color: #4431B8;
    font-size: 18px;
    font-weight: 500;
}

.preview_container {
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
}

.preview_container:hover {
    background-color: black;
}

.preview_container:hover img {
    opacity: 0.5;
}

.preview_container:hover .file_delte_icon {
    display: block !important;
}

.uploaded_file {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.file_delte_icon {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    display: none !important;
}