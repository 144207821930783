.container {
    display: grid;
    /* grid-template-columns: 0.3fr repeat(5, 1fr); */
    grid-template-columns: 0.3fr 1fr repeat(6, 1fr);
    align-items: center;
    gap: 0.5rem;
}

.container>div {
    padding: 5px;
}

/* .user_column>span {
    color: var(--primary-text);
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
} */

/* .dark_label:not(:first-child),
.label {
    text-align: center;
} */

.label {
    color: #4E4E4E;
    font-size: 16px;
}

.dark_label {
    color: var(--primary-text);
    font-size: 15px;
    font-weight: 500;
}

.action_container {
    display: flex;
    gap: 0.7rem;
    /* justify-content: center; */
}

.status_container {
    display: flex;
    /* justify-content: center; */
}

.status {
    text-align: center;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    width: max-content;
    border-radius: 4px;
    padding: 3px 10px;
    /* cursor: pointer; */
}

.verified_status {
    color: #34A855;
    background-color: #34A8550F;
    border-color: #34A855;
}

.not_verified_status {
    color: #595959;
    background-color: #9696960F;
    border-color: #595959;
}

.edit_btn {
    height: 30px !important;
    width: 30px !important;
    background-color: #007DFF1F !important;
}

@media screen and (max-width: 900px) {

    .label,
    .dark_label {
        font-size: 10px;
    }
}

@media screen and (max-width: 767px) {

    .label,
    .dark_label {
        font-size: 9px;
    }

    .status {
        font-size: 7px;
        padding: 1px 5px;
        border-radius: 1px;
    }

    .icon_btn {
        height: 20px !important;
        width: 20px !important;
    }
}