.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #FBFCFC;
}

.search_bar {
    display: flex;
    gap: 0.5rem;
    padding: 0 1rem;
}

.search_btn {
    border-radius: 7.968px !important;
}

.search_field :global .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.search_field {
    font-size: 11.5px !important;
    border-radius: 13.28px !important;
    background: #F4F2FF !important;
}

.search_field :global .MuiOutlinedInput-input {
    padding: 14.5px 12px !important;
}