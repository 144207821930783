.main {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    position: relative;
    border-radius: 6.196px;
    border: 1px solid #D0D0D0;
    max-width: 307.217px;
    min-width: 300px;
    padding: 1.5rem;
}

.main::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 80%;
    border-radius: 10px;
    background-color: var(--primary);
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
}

@media screen and (max-width:1150px) {
    .main {
        max-width: 100%;
    }
}

.note {
    color: var(--primary-text);
    font-size: clamp(10px, 2vw, 12px);
}