.container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000042;
    z-index: 1;
}

.close {
    display: none;
}

.container>div {
    z-index: 2;
}