.iframe {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iframe>svg,
.iframe>img {
    width: 75% !important;
    height: 75% !important;
}