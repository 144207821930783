.container {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 0.5rem;
    padding: 0 1rem;
    border-radius: 8px;
    background: #F6F8FD;
}

.input :global .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.input {
    box-shadow: none !important;
    flex: 1 !important;
}

.action_btn {
    background-color: var(--primary) !important;
    width: 30px !important;
    height: 30px !important;
}