// Sidebar menu dark color scheme

.app-sidebar {
  &--dark {
    .sidebar-navigation {
      .sidebar-menu-box {
        background: rgba($black, 0.15);
      }

      .sidebar-header {
        color: rgba($white, 0.35);
      }

      ul {
        li {
          & > a {
            color: rgba($white, 0.65);

            .sidebar-icon {
              & > * {
                color: $white;
                opacity: 0.5;
              }
            }
          }

          ul {
            li {
              a {
                &::after {
                  background: rgba($black, 0.2);
                }

                &:hover,
                &.active {
                  &::after {
                    background: rgba($black, 0.5);
                  }
                }
              }
            }
          }

          & > a:hover,
          & > a.active {
            color: rgba($white, 1);
            background: rgba($black, 0.15);

            .sidebar-icon-indicator {
              opacity: 0.7;
            }

            .sidebar-icon {
              & > * {
                opacity: 1;
              }
            }
          }
        }
      }

      ul > a {
        color: rgba($white, 1);
        margin: 1px 0;
        // background: rgba($black, 0.15);
        border-left: 4px solid transparent;

        // icon and text
        & > div > div {
          color: rgba($white, 1);
          // background: rgba($black, 0.15);
        }

        // icon
        & > div > .sidebar-icon {
          & > * {
            opacity: 0.6;
          }
        }

        &::after {
          background: rgba($black, 0.2);
        }

        &.active {
          border-left-color: $gray-200;
        }

        &:hover,
        &.active {
          background: rgba($kargo-blue-light, 0.8);

          //Icon and Text
          & > div > div {
            color: rgba($kargo-blue, 1);
          }

          // Icon
          & > div > .sidebar-icon {
            & > * {
              opacity: 1;
            }
          }

          // Right-Chevron indicator
          .sidebar-icon-indicator {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
.app-sidebar-collapsed {
  .app-sidebar--dark:not(:hover) {
    .app-sidebar--content {
      .sidebar-navigation {
        .sidebar-header {
          &::after {
            background: rgba($white, 0.15);
          }
        }
      }
    }
  }
}
