.stepper :global .MuiStepLabel-alternativeLabel {
    margin-top: 0px !important;
}

.stepper :global .MuiStepLabel-label {
    line-height: 1 !important;
}


.dot_step {
    border-radius: 50%;
    height: 6.5px;
    width: 6.5px;
    position: relative;
    top: 15px;
    transform: translate(0, -2.1px);
    z-index: 15;
    background-color: #ccc;
}


@media screen and (max-width:700px) {
    .dot_step {
        top: 7.5px;
    }

}

.dot_step_error {
    background-image: linear-gradient(90deg, rgba(248, 50, 69, 1) 0%, rgba(248, 50, 69, 1) 64%);
}

.dot_step_active {
    background-image: linear-gradient(90deg, rgba(52, 168, 85, 1) 0%, rgba(52, 168, 85, 1) 64%);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .25);
}

.dot_step_complete {
    background-image: linear-gradient(90deg, rgba(52, 168, 85, 1) 0%, rgba(52, 168, 85, 1) 64%);
}