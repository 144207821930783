//  Variables

$app-content-sidebar-width: 70%;
$app-content-sidebar-sm-width: 60%;
$app-content-sidebar-lg-width: 50%;
$app-content-sidebar-xl-width: 45%;
$app-content-sidebar-xxl-width: 40%;
$btn-pane-height: 80px;

$sidebar-width-collapsed: 100px;

$sidebar-shadow-light: 0px 0px 28px 0px rgba($primary, 0.08);
