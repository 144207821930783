.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    height: 100%;
    width: 100%;
}

.main {
    background: #ffffff;
    max-width: 800px;
    width: 90%;
    overflow-x: visible;
    height: 100%;
    position: relative;
}

.inner_main {
    padding: 1rem 2rem;
    height: 100%;
    overflow-y: auto;
}

.close_btn {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    transform: translate(50%, -50%) !important;
}