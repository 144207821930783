.container {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding: 1rem 2rem;
    border-radius: 4.098px;
    /* background: rgba(236, 238, 248, 0.60); */
    background: #fff
}

.title {
    color: var(--primary-text);
    font-weight: 700;
    font-size: 0.9rem;
}

.top_details {
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 0.7rem 2rem;
    width: 100%;
}

.top_details>div {
    flex: 1;
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0.7rem 1rem;
}

.label {
    color: var(--primary-text);
    font-size: 0.85rem;
    font-weight: 800;
}

.value {
    color: var(--secondary-text);
    font-size: 0.85rem;
    display: flex;
    gap: 0.5rem;
}

.simple_grid {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0.7rem 1rem;
}

.actions_container {
    display: flex;
    align-items: center;
    gap: 1rem;
}